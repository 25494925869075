window.tableRowClick = function(e, link) {
    var tagName = e.target.tagName;
    var className = e.target.className.trim();

    // console.log("YES 0------------")
    // console.log(e.target.tagName)
    // console.log(e.target.className)

    if (className != "myicon") {
      if (tagName == "DIV" || tagName == "TD" || tagName == "TR" || tagName == "P" || tagName == "IMG" || tagName == "SPAN") {
        Turbo.visit(link)
      }
    }

}

window.tableTurboRowClick = function(e, link) {
    var tagName = e.target.tagName;
    var className = e.target.className.trim();


    var elementsWithJobId = document.querySelectorAll('[data-jobid]');

    elementsWithJobId.forEach(function(element) {
      element.classList.remove('selected-tr'); // Replace 'your-class-name' with the actual class name you want to remove
    });

    let dataJobId = e.target.dataset.jobid; // Use 'jobid' with a lowercase 'i'
    var trElement = document.querySelector(`tr[data-jobid="${dataJobId}"]`);


    trElement.classList.add("selected-tr");

    //console.log(trElement)

    if (className != "myicon") {

      if (e.metaKey) {
        window.open(link, '_blank').focus();
      }

      if (tagName == "DIV" || tagName == "TD" || tagName == "TR" || tagName == "P" || tagName == "IMG" || tagName == "SPAN" || tagName == "H2" || tagName == "H3") {
        //console.log("Tagname clicked: " + tagName)
        let frame = document.querySelector('turbo-frame#job')
        frame.src = link
        // frame.reload()
        // history.replaceState({}, link, link);
      }
    }

}

window.tableTurboRowClickBanner = function(e, link) {
    var tagName = e.target.tagName;
    var className = e.target.className.trim();

    if (className != "myicon") {

      // if (e.metaKey) {
      //   window.open(link, '_blank').focus();
      // }

      if (tagName == "DIV" || tagName == "TD" || tagName == "TR" || tagName == "P" || tagName == "IMG" || tagName == "SPAN" || tagName == "H2" || tagName == "H3" || tagName == "IMG" || tagName == "A") {
        // history.replaceState({}, link, link);
        window.location.href = link;
      }
    }

}

window.spanTagsClick = function(e, link) {
    var tagName = e.target.tagName;
    var className = e.target.className.trim();

    // console.log("YES 111------------")
    // console.log(e.target.tagName)
    // console.log(e.target.className)

    if (className != "myicon") {
      if (tagName == "SPAN") {
          Turbo.visit(link, { action: "replace" })
      }
    }



}